import mapValues from 'lodash.mapvalues';

// 异步枚举获取配置
const enumsConfig = {
  roles: {
    url: '/admin-api/role/listSelect',
    init: true,
    method: 'GET',

    responseIntercepter: (data) => {
      return data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        }
      })
    },
  },

  rootCategories: {
    url: '/admin-api/category/list',
    init: true,
    method: 'GET',
    params: {
      limit: 1000,
      page: 1,
      parentId: 0,
    },
    responseIntercepter: (data) => {
      return data.items.map((item) => {
        return {
          label: item.name,
          value: item.id,
        }
      })
    },
  },


  // shippingList: {
  //   url: '/admin-api/shop/shipping/list',
  //   init: true,
  //   method: 'GET',
  //   params: {
  //     limit: 1000,
  //     page: 1,
  //   },
  //   responseIntercepter: (data) => {
  //     return data.items.map((item) => {
  //       return {
  //         label: item.name,
  //         value: item.code,
  //       }
  //     })
  //   },
  // },


};

const asyncEnums = mapValues(enumsConfig, () => []);

export {
  asyncEnums as default,
  enumsConfig,
};
