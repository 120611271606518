import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout';

Vue.use(VueRouter);


export const constantRouterMap = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true,
  },
  {
    path: '/404',
    component: () => import('@/views/errorPage/404'),
    hidden: true,
  },
  {
    path: '/401',
    component: () => import('@/views/errorPage/401'),
    hidden: true,
  },
  {
    path: '',
    component: Layout,
    redirect: 'dashboard',
    children: [{
      path: 'dashboard',
      component: () => import('@/views/dashboard/index'),
      name: 'dashboard',
      meta: {
        title: '首页',
        icon: 's-home',
        affix: true,
      },
    }],
  },

  {
    path: '*',
    component: () => import('@/views/errorPage/404'),
    hidden: true,
  },
];

export default new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap,
});

/**
 * 动态路由，根据权限添加
 */
export const asyncRouterMap = [
  {
    path: '/sys',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '系统管理',
      icon: 's-tools',
    },
    children: [
      // {
      //   path: '/helpManagement',
      //   component: () => import('@/views/helpManagement/index'),
      //   name: 'helpManagement',
      //   meta: {
      //     title: '帮助管理',
      //     icon: 'config',
      //     permission: ['sysHelp/list'],
      //   },
      // },
      // {
      //   path: '/noticeManagement',
      //   component: () => import('@/views/noticeManagement/index'),
      //   name: 'noticeManagement',
      //   meta: {
      //     title: '公告管理',
      //     // icon: 'config',
      //     // permission: ['announcement/list'],
      //   },
      // },
      {
        path: '/roleManagement',
        component: () => import('@/views/roleManagement/index'),
        name: 'roleManagement',
        meta: {
          title: '角色管理',
          // icon: 'config',
          permission: ['sys:role'],
        },
      },
      {
        path: '/adminManagement',
        component: () => import('@/views/adminManagement/index'),
        name: 'adminManagement',
        meta: {
          title: '管理员管理',
          // icon: 'config',
          permission: ['sys:admin'],

        },
      }, {
        path: '/releaseManagement',
        component: () => import('@/views/releaseManagement/index'),
        name: 'releaseManagement',
        meta: {
          title: 'APP版本管理',
          // icon: 'config',
          permission: ['sys:appOnline'],

        },
      },
    ],
  },


  {
    path: '/shop',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '商城管理',
      icon: 's-shop',
    },
    children: [{
      path: '/shop/categoryManagement',
      component: () => import('@/views/shop/categoryManagement/index'),
      name: 'categoryManagement',
      meta: {
        title: '类目管理',
        // icon·: 'config',
        permission: ['shop:category'],
      },
    },
    {
      path: '/shop/secondCategoryManagement/:id',
      component: () => import('@/views/shop/secondCategoryManagement/index'),
      name: 'secondCategoryManagement',
      hidden: true,
      meta: {
        title: '二级类目管理',
        // icon: 'config',
        permission: ['shop:category'],
      },
    },

    {
      path: '/shop/thirdCategoryManagement/:id',
      component: () => import('@/views/shop/thirdCategoryManagement/index'),
      name: 'thirdCategoryManagement',
      hidden: true,
      meta: {
        title: '三级类目管理',
        // icon: 'config',
        permission: ['shop:category'],
      },
    },

    // {
    //   path: '/shop/categoryBannerManagement',
    //   component: () => import('@/views/shop/categoryBannerManagement/index'),
    //   name: 'categoryBannerManagement',
    //   meta: {
    //     title: '类目Banner管理',
    //     // icon: 'config',
    //     // permission: ['chain/miner/list'],
    //   },
    // },

    {
      path: '/shop/attributeManagement/:id',
      component: () => import('@/views/shop/attributeManagement/index'),
      name: 'attributeManagement',
      hidden: true,
      meta: {
        title: '属性管理',
        // icon: 'config',
        permission: ['shop:attribute'],
      },
    },
    {
      path: '/shop/brandManagement/:id',
      component: () => import('@/views/shop/brandManagement/index'),
      name: 'brandManagement',
      meta: {
        title: '品牌管理',
        // icon: 'config',
        permission: ['shop:brand'],
      },
    },

    {
      path: '/shop/goodsManagement',
      component: () => import('@/views/shop/goodsManagement/index'),
      name: 'goodsManagement',
      meta: {
        title: '商品管理',
        // icon: 'config',
        permission: ['shop:product'],
      },
    },

    // {
    //   path: '/shopBannerManagement',
    //   component: () => import('@/views/shopBannerManagement/index'),
    //   name: 'shopBannerManagement',
    //   meta: {
    //     title: '商城Banner管理',
    //     // icon: 'config',
    //     // permission: ['trading/processResult'],

    //   },
    // },

    {
      path: '/shop/orderList',
      component: () => import('@/views/shop/orderList/index'),
      name: 'shopOrderList',
      meta: {
        title: '订单管理',
        // icon: 'config',
        permission: ['order/list'],

      },
    },

    // {
    //   path: '/shop/recommend',
    //   component: () => import('@/views/shop/recommendManagement/index'),
    //   name: 'shopRecommend',
    //   meta: {
    //     title: '首页推荐',
    //     // icon: 'config',
    //     // permission: ['trading/processResult'],

    //   },
    // },
    // {
    //   path: '/shop/commentManagement',
    //   component: () => import('@/views/shop/commentManagement/index'),
    //   name: 'commentManagement',
    //   meta: {
    //     title: '评论管理',
    //     // icon: 'config',
    //     // permission: ['trading/processResult'],

    //   },
    // },

    ],
  },
];
