/* eslint-disable */
import axios from 'axios';
import qs from 'qs';
import clonedeep from 'lodash.clonedeep';
import pathToRegexp from 'path-to-regexp';
import { Message } from 'element-ui';

/**
 * 请求成功状态码
 */
export const SUCCESS_BIZ_CODE = 0;

/**
 * 不同的空格编码方式
 * @see https://github.com/ljharb/qs#rfc-3986-and-rfc-1738-space-encoding
 */
// const FORMAT_ENCODE = 'RFC3986';

/**
 * 包含请求体的请求类型
 */
const WITH_BODY_REQUESTS = ['put', 'post', 'patch'];

class Http {
  constructor({
    urlencoded = false,
    ...axiosOptions
  } = {}) {
    this.options = {
      urlencoded,
    };

    this.axiosInstance = axios.create(axiosOptions);
  }

  request(options) {
    return this.fetch(Object.assign({}, this.options, options))
      .then((response) => {
        const { status: statusCode, statusText, data: resData } = response;
        const { code: bizCode, message, result: data } = resData;
        const success = SUCCESS_BIZ_CODE === bizCode;
        const errMsg = message || 'Server Error';

        if (!success && !options.hideMessage) {
          Message.error(errMsg);
        }
        return {
          success,
          message: errMsg,
          statusCode,
          bizCode,
          data,
        };
      })
      .catch((error) => {
        const { response } = error;
        let msg;
        let statusCode;
        let bizCode;
        if (response && response instanceof Object) {
          const { statusText, data: resData } = response;
          const { message: resMsg } = resData;
          statusCode = response.status;
          bizCode = resData.status;
          msg = resMsg || statusText;
        } else {
          statusCode = 600;
          msg = error.message || 'Network Error';
        }

        if (!options.hideMessage) {
          Message.error(msg);
        }
        return {
          success: false,
          message: msg,
          statusCode,
          bizCode,
        };
      });
  }

  fetch(options) {
    let {
      url,
      method = 'get',
      data,
      urlencoded,
      ...restOptions
    } = options;

    method = method.toLowerCase();

    /**
     * 使用clone对象，避免直接修改传入data
     */
    if (!(data instanceof FormData)) {
      data = clonedeep(data);
    }

    /**
     * 拼接路径参数，将路径参数在data中删除
     */
    try {
      let domin = '';
      if (url.match(/[a-zA-z]+:\/\/[^/]*/)) {
        [domin] = url.match(/[a-zA-z]+:\/\/[^/]*/);
        url = url.slice(domin.length);
      }
      const match = pathToRegexp.parse(url);
      url = pathToRegexp.compile(url)(data);
      for (let i = 0; i < match.length; i++) {
        const item = match[i];
        if (item instanceof Object && item.name in data) {
          delete data[item.name];
        }
      }
      url = domin + url;
    } catch (e) {
      throw new Error(e.message);
    }
    /**
     * 请求参数对象
     */
    const requestOptions = {
      url,
      method,
      ...restOptions,
    };

    if (WITH_BODY_REQUESTS.includes(method)) {
      requestOptions.data = data;
    } else {
      requestOptions.params = data;
    }

    /**
     * 当请求格式为application/x-www-form-urlencoded时需要将请求体格式化为URLSearchParams
     * @see https://github.com/axios/axios#using-applicationx-www-form-urlencoded-format
     */
    if (urlencoded) {
      requestOptions.transformRequest = [(requestData) => {
        if (!(requestData instanceof FormData)) {
          return qs.stringify(requestData);
        }
        return requestData;
      }];
      requestOptions.paramsSerializer = params => qs.stringify(params);
    }

    return this.axiosInstance(requestOptions);
  }
}

export default Http;
