
export default {
  // 用户状态
  categoryLang: [
    {
      value: 'zh-CN',
      label: 'zh-CN',
    },
    {
      value: 'en-US',
      label: 'en-US',
    },
  ],

  attrShowType: [
    {
      value: 0,
      label: '自定义',
    },
    {
      value: 1,
      label: '单选',
    },
    // {
    //   value: 2,
    //   label: '多选',
    // },
  ],

  attrType: [
    {
      value: 0,
      label: '非关键属性',
    },
    {
      value: 1,
      label: '关键属性',
    },
    {
      value: 2,
      label: '销售属性(sku)',
    },
  ],

  commentState: [
    {
      value: 0,
      label: '未评价',
    },
    {
      value: 1,
      label: '已评价',
    },
  ],
  confirmState: [
    {
      value: 0,
      label: '未确认',
    },
    {
      value: 1,
      label: '已确认',
    },
  ],

  shopPayState: [
    {
      value: 0,
      label: '未付款',
    },
    {
      value: 1,
      label: '付款中',
    },
    {
      value: 2,
      label: '已付款',
    },
    {
      value: 4,
      label: '退款中',
    },
    {
      value: 5,
      label: '已退款',
    },
  ],

  shopingState: [
    {
      value: 0,
      label: '未发货',
    },
    {
      value: 1,
      label: '已发货',
    },
    {
      value: 2,
      label: '已收货',
    },
    {
      value: 4,
      label: '退货',
    },
    {
      value: 5,
      label: '已退款',
    },
  ],
  // 订单状态：0->待付款；1->待发货 2->已发货 3->已完成 4->已关闭 5->无效订单
  shopOrderState: [
    {
      value: 0,
      label: '待付款',
    },
    {
      value: 1,
      label: '待发货',
    },
    {
      value: 2,
      label: '已发货',
    },
    {
      value: 3,
      label: '已完成',
    },
    {


      value: 4,
      label: '已关闭',
    },
    {
      value: 5,
      label: '无效订单',
    },
  ],

  shopPayType: [
    {
      value: 0,
      label: '未支付',
    },
    {
      value: 1,
      label: 'USDT',
    },
  ],

  shopCommentType: [
    {
      value: 0,
      label: '评价',
    },
    {
      value: 1,
      label: '追加评价',
    },
    {
      value: 2,
      label: '商家回复',
    },
  ],

  tradeState: [
    {
      value: 0,
      label: '待付款',
    },
    {
      value: 1,
      label: '待发货',
    },
    {
      value: 2,
      label: '已发货',
    },
    {
      value: 3,
      label: '已完成',
    },
    {
      value: 4,
      label: '已关闭|已取消',
    },
    {
      value: 5,
      label: '无效订单',
    },
    {
      value: 6,
      label: '待退款',
    },
    {
      value: 7,
      label: '已退款',
    },
    {
      value: 8,
      label: '待评价',
    },
    {
      value: 9,
      label: '已评价',
    },
  ],
};
