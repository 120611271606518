import shopEnums from './shop';

const staticEnums = {
  ...shopEnums,
  // 用户状态
  userStatus: [
    {
      value: 0,
      label: '禁用',
    },
    {
      value: 1,
      label: '正常',
    },
  ],

  // 认证状态 0 未认证 1 认证中 2 认证通过 3 认证失败
  identityState: [
    {
      value: 0,
      label: '未认证',
    },
    {
      value: 1,
      label: '认证中',
    },
    {
      value: 2,
      label: '认证通过',
    },
    {
      value: 3,
      label: '认证失败',
    },
  ],

  // 审核状态 apply audited reject
  identityAuditState: [
    {
      value: 0,
      label: '待审核',
    },
    {
      value: 2,
      label: '已通过',
    },
    {
      value: 3,
      label: '已拒绝',
    },
  ],
  // 证件类型
  identityType: [
    {
      value: 0,
      label: '身份证',
    },
  ],

  verifyState: [
    {
      value: 0,
      label: '未审核',
    },
    {
      value: 1,
      label: '审核通过',
    },
    {
      value: 2,
      label: '审核拒绝',
    },
  ],

  publishState: [
    {
      value: 0,
      label: '下架',
    },
    {
      value: 1,
      label: '上架',
    },
  ],

  // 1 // 姓氏不正确
  // 2 // 名字不正确
  // 3 // 身份证件类型不正确
  // 4 // 公民身份号码或护照号码不正确
  // 5 // 证件图片不清晰
  // 6 // 您的身份证或护照号码已被使用

  // indentityFailType: [
  //   {
  //     value: 1,
  //     label: '姓氏不正确',
  //   },{
  //     value: 2,
  //     label: '名字不正确',
  //   },{
  //     value: 3,
  //     label: '身份证件类型不正确',
  //   },{
  //     value: 4,
  //     label: '公民身份号码或护照号码不正确',
  //   },{
  //     value: 5,
  //     label: '证件图片不清晰',
  //   },{
  //     value: 6,
  //     label: '您的身份证或护照号码已被使用',
  //   }
  // ],
  // 审核状态 apply audited reject
  auditState: [
    {
      value: 'apply',
      label: '待审核',
    },
    {
      value: 'audited',
      label: '已通过',
    },
    {
      value: 'reject',
      label: '已拒绝',
    },
    {
      value: 'refunding',
      label: '退款中',
    },
    {
      value: 'refunded',
      label: '已退款',
    },
  ],
  // 支付状态
  payState: [
    {
      value: 0,
      label: '否',
    },
    {
      value: 1,
      label: '是',
    },
  ],
  // waiting pending confirm failed
  chainPayStatus: [
    {
      value: 'waiting',
      label: '等待',
    },
    {
      value: 'pending',
      label: '付款中',
    },
    {
      value: 'confirm',
      label: '已确认',
    },
    {
      value: 'failed',
      label: '已失败',
    },
  ],

  // 支付状态
  dealState: [
    {
      value: 0,
      label: '未处理',
    },
    {
      value: 1,
      label: '已处理',
    },
  ],
  // 证件类型
  lockStatus: [
    {
      value: 0,
      label: '未锁定',
    },
    {
      value: 1,
      label: '锁定',
    },
  ],


  // yes or no
  yesOrNo: [
    {
      value: 0,
      label: '否',
    },
    {
      value: 1,
      label: '是',
    },
  ],

  // 启用禁用
  onOrOff: [
    {
      value: 0,
      label: '禁用',
    },
    {
      value: 1,
      label: '正常',
    },
  ],

  buyOrSell: [
    {
      value: 0,
      label: '买',
    },
    {
      value: 1,
      label: '卖',
    },
  ],
  // 异常状态
  exceptionState: [
    {
      value: 0,
      label: '异常',
    },
    {
      value: 1,
      label: '正常',
    },
  ],

  // 挂单类型 0市价单 1限价单
  orderType: [
    {
      value: 0,
      label: '市价单',
    },
    {
      value: 1,
      label: '限价单',
    },
  ],
  // 订单状态  0 交易中 1 已完成 2已取消 3超时 4部分成交
  orderState: [
    {
      value: 0,
      label: '交易中',
    },
    {
      value: 1,
      label: '已完成',
    },
    {
      value: 2,
      label: '已取消',
    },
    {
      value: 3,
      label: '超时',
    },
    {
      value: 4,
      label: '部分成交',
    }
  ],
  // 分类 0 新手指南,1 常见问题,2 充值指南,3 交易指南,4 APP二维码
  helpCategory: [
    {
      value: 0,
      label: '新手指南',
    },
    {
      value: 1,
      label: '常见问题',
    },
    {
      value: 2,
      label: '充值指南',
    },
    {
      value: 3,
      label: '交易指南',
    },
    {
      value: 4,
      label: 'APP二维码',
    }
  ],
  // pos矿池状态
  posProductState: [
    {
      value: 0,
      label: '未开标',
    },
    {
      value: 1,
      label: '开标中',
    },
    {
      value: 2,
      label: '已结束',
    },

  ],
  // 1 随进随出 2固定期限
  posType: [
    {
      value: 1,
      label: '随进随出',
    },
    {
      value: 2,
      label: '固定期限',
    },
  ],

  posReleaseType: [
    {
      value: 0,
      label: '到期解锁',
    },
    {
      value: 1,
      label: '手动解锁',
    },
  ],

  posInvestState: [
    {
      value: 1,
      label: '投资中',
    },
    {
      value: 2,
      label: '赎回中',
    },
    {
      value: 3,
      label: '已赎回',
    },
    {
      value: 4,
      label: '废弃',
    },
  ],

  platform: [
    {
      value: 1,
      label: '安卓',
    },
    {
      value: 2,
      label: 'IOS',
    },
  ],

  bannerType: [
    {
      value: 0,
      label: 'app首页轮播',
    },
    {
      value: 1,
      label: 'pc首页轮播',
    },
  ],

  bannerBizType: [
    {
      value: 0,
      label: '不关联',
    },
    {
      value: 1,
      label: '关联公告',
    },
    {
      value: 2,
      label: '关联帮助中心',
    },
  ],
  minerPayStatus: [
    {
      value: 0,
      label: '未抵押',
    },
    {
      value: 1,
      label: '抵押上链中',
    },
    {
      value: 2,
      label: '已抵押',
    },
  ],

  minerStatus: [
    {
      value: 0,
      label: '未开机',
    },
    {
      value: 1,
      label: '挖矿中',
    },
    {
      value: 2,
      label: '故障中',
    },
  ],

  lolState: [
    {
      value: 0,
      label: '不免',
    },
    {
      value: 1,
      label: '免抵押',
    },
  ],


  // 上线
  onlineStatus: [
    {
      value: 0,
      label: '未上线',
    },
    {
      value: 1,
      label: '已上线',
    },
  ],

  // 上线
  cloudProductStatus: [
    {
      value: 0,
      label: '下架',
    },
    {
      value: 1,
      label: '上架',
    },
  ],

  investReleaseState: [
    {
      value: 1,
      label: '投资中',
    },
    {
      value: 2,
      label: '释放完毕',
    },
  ],

  minerType: [
    {
      value: 1,
      label: '虚拟矿机',
    },
    {
      value: 2,
      label: '物理机',
    },
  ],

  payStatus: [
    {
      value: 0,
      label: '未支付',// 0 1支付中 2支付完成
    },
    {
      value: 1,
      label: '支付中',// 0 1支付中 2支付完成
    },
    {
      value: 2,
      label: '支付完成',// 0 1支付中 2支付完成
    }
  ],
  minerRunState: [
    {
      value: 1,
      label: '进行中',
    },
    {
      value: 2,
      label: '挖矿完成',
    },
  ]
};

export default staticEnums;
